.stash-search-items-per-page {
    width: 3em;
    margin-right: 0.25em;
}


.stash-pagination {    
    padding-left: 0.25em;
    padding-right: 0.25em;
    display: inline-block;    
    margin-right: 0.25em;    
}


@media screen and (max-width: 500px) {

    .stash-pagination {
        margin-top: 0.25em;
        float: right;        
        width: 100%;
    }
    .stash-pagination .stash-search-items-per-page {
        width: 45%;
        float: left;
    }    
    .stash-pagination .stash-pagination-buttons {
        width: 45%;
        float: right;
        margin-right: 0px;
    }

    
    .stash-search-buttons {
        width: 100%;
    }

}