.sp-button-container > div {
    display: inline;
    margin-right: 0.5em;
}

/* 
@media screen and (max-width: 930px) {
    .sp-button-container > div {
        
    }    
}
 */
 .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 100, 100, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay > div {
    color: brown;
    font-size: 10em;    
  }


.ilb {
    display: inline-block;
}

.post {      
  background-color: #efefef;
  display: inline-block;
  margin-right: 0.5em;
  cursor: pointer;
  margin-bottom: 0.5em;
  
}

.post .post-img {
    height: 200px;
    width: 200px;
    margin: 1em;
}

.stash {
    display: block;
    margin-top: 10px;
    width: 100%;    
    height: 100%;
    /* background-color: #ddd; */
}

.post-img-group {
    width: 100%!important;
    border: 1px solid red!important;
}

.post-modal-title {
    width: 100%;
}

.post-icons {
    text-align: right;
    padding-right: 1em;
    padding-bottom: 0.25em;
}

.post-icons .post-icon-item {
    margin-left: 0.25em;
}

.stash-tags-buttons .btn {
    margin-left: 0.20em;
}

.clickable {
    cursor: pointer;
}

.post-detail-icon-col {
    margin-right: 1em;
    
}

.post-detail-segment {
    padding: 0.25em;        
    display: block;
}

.post-detail-segment-ocr {    
    margin-left: 1em;
}


.stash-tag-dropdown-button {
    margin-right: 0.15em;
}

.stash-search-input {
    margin-right: 0.25em;
}

.stash-clear-button {
    margin-right: 0.5em;
}

.post-img-modal-tag-btn {
    margin-right: 0.5em;
    margin-top: 0.5em;
}

.stash-results-count  { margin-right: 0.25em; }

.stash-tag-manager .tag-manager-open-btn {
    margin-right: 0.5em;       
}

.stash-content-type-dropdown-button {
    margin-right: 0.5em;
}

.stash-modal-video {
    max-width: 500px;
}

.dropzone-box {
    height: 120px;
    width: 100%;
    background-color: #aaa;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

@media screen and (max-width: 1024px) {
    .stash-modal-video {
        max-width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .sp-button-container > div {
        display: block;        
    }    

    .post {
        width: 100%;
        margin: none!important;
        padding: none!important;
        
    }

    .post .post-img {        
        width: 90%;
        height: 100%;
    }

    .btn {
        width: 100%;
    }    

    

    .stash-search-input {
        width: 100%;
    }
    .stash-search-button {        
        width: 45%; 
        margin-top: 0.25em;       
    }

    .stash-clear-button {        
        float: right;
        width: 45%;
        margin-top: 0.25em;       
        margin-right: 0px;
    }


    .stash-tag-dropdown-button {
        margin-top: 0.25em;       
        width: 45%;
    }

    .stash-tag-manager .tag-manager-open-btn {
        margin-top: 0.25em;       
        width: 45%;
        float: right;
        margin-right: 0px;
    }

    .post-img-modal-tag-btn {
        width: auto;         
    }

    .stash-tag-manager .dropdown-menu {
        width: 100%;
    }

    .stash-results-count, .stash-size { margin: none; margin-top: 0.25em; width:45%; }

    .stash-size { width: 100%; }


    .stash-content-type-dropdown-button {        
        margin: none;
        width: 100%;        
        margin-top: 0.25em;
    }

    .stash-modal-video {
        max-width: 100%;
    }

    

}
